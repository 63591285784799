var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "SpainhnNavigationBreadcrumb",
        { attrs: { "navigation-links": _vm.links } },
        [
          _vm._t("default", function () {
            return [
              _c(
                "svg",
                {
                  staticClass: "mx-1 my-auto",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "2",
                    height: "14",
                    viewBox: "0 0 2 14",
                    fill: "none",
                  },
                },
                [_c("path", { attrs: { d: "M1 0V13.5", stroke: "white" } })]
              ),
              _c(
                "small",
                {
                  staticClass:
                    "spainhn-small-text text-white my-auto px-1 flex-grow-1 text-start variable-item",
                  attrs: { role: "button" },
                  on: { click: () => this.$router.push("/contacto") },
                },
                [_vm._v(_vm._s(_vm.$t("default.SERVICES_BAR_CONTACT")))]
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "container-fluid d-flex justify-content-center pb-2 spainhn-bg-gris-cajas pt-2",
        },
        [
          _c(
            "div",
            { staticClass: "pt-4 d-flex flex-wrap" },
            [
              _c("div", { staticClass: "col-md-7 col-12 ps-md-3 ps-4 pe-4" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "spainhn-text ms-auto mt-3 me-0 text-center spainhn-subtitle-2-black",
                    staticStyle: { "font-size": "16px !important" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("default.PACK_MICROSITES.WELCOME_PACK")) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "mdb-btn",
                {
                  staticClass: "spainhn-button me-lg-0 me-auto mt-1 ms-auto",
                  attrs: { color: "indigo", size: "sm" },
                  on: { click: _vm.goToBookingSystem },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "my-auto" },
                        [
                          _c(
                            "icon-base",
                            { attrs: { height: 50, width: 50 } },
                            [_c("icon-carrito")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("h6", [
                        _c("strong", { staticClass: "spainhn-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("default.PACK_MICROSITES.BUY_PACK")
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(_vm._s(_vm.precioOrientativoPaquete) + " "),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "container-fluid px-5" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-around pt-4 mb-3" },
            [
              _c(
                "div",
                { staticClass: "d-flex", staticStyle: { width: "100%" } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "my-auto d-none d-md-flex",
                    attrs: { icon: "fa-compass", size: "3x" },
                  }),
                  _c(
                    "div",
                    { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 mt-auto spainhn-subtitle-2-black",
                        },
                        [_vm._v(" " + _vm._s(_vm.paquete?.name) + " ")]
                      ),
                      _c(
                        "small",
                        {
                          staticClass: "mb-0 mt-auto spainhn-subtitle-4-black",
                        },
                        [_vm._v(_vm._s(_vm.address))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("b-spinner", {
            class: { "d-none": !_vm.loading },
            staticStyle: { width: "5rem", height: "5rem" },
            attrs: { variant: "danger", size: "2x" },
          }),
          _vm.entradas
            ? _c(
                "div",
                { staticClass: "d-flex mb-4" },
                [
                  _c(
                    "mdb-row",
                    [
                      _c("mdb-col", { attrs: { col: "12", lg: "8" } }, [
                        _c("div", { staticClass: "d-flex flex-column w-100" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                            },
                            [
                              _c(
                                "mdb-row",
                                [
                                  _c(
                                    "mdb-col",
                                    {
                                      staticClass:
                                        "text-start spainhn-text pt-0 pe-0 mb-1 mb-md-5 px-0",
                                      staticStyle: { height: "100%" },
                                      attrs: { col: "12" },
                                    },
                                    [
                                      _c(
                                        "b-carousel",
                                        {
                                          staticStyle: {
                                            "text-shadow": "1px 1px 2px #333",
                                          },
                                          attrs: {
                                            id: "carousel",
                                            interval: 4000,
                                            controls: "",
                                            indicators: "",
                                            "img-width": "940",
                                            "img-height": "528",
                                          },
                                          on: {
                                            "sliding-start": _vm.onSlideStart,
                                            "sliding-end": _vm.onSlideEnd,
                                          },
                                          model: {
                                            value: _vm.slide,
                                            callback: function ($$v) {
                                              _vm.slide = $$v
                                            },
                                            expression: "slide",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.imagenes,
                                            function (imagen, index) {
                                              return _c("b-carousel-slide", {
                                                key: index,
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "img",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-3 d-flex mx-auto carousel-homeslide px-0 mx-0",
                                                              staticStyle: {
                                                                height: "344px",
                                                                width: "940px",
                                                                "max-width":
                                                                  "100vw",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "my-md-auto mx-auto carousel-spainhn",
                                                                staticStyle: {
                                                                  height:
                                                                    "auto",
                                                                  width: "auto",
                                                                  "max-width":
                                                                    "940px",
                                                                  "max-height":
                                                                    "344px",
                                                                  display:
                                                                    "block",
                                                                  "object-fit":
                                                                    "cover",
                                                                },
                                                                attrs: {
                                                                  src: `${_vm.imagenesUrl}/${imagen.name}`,
                                                                  alt: imagen?.altText,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            }
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "carousel-control-prev",
                                              attrs: {
                                                "aria-controls":
                                                  "monumentosShow___BV_inner_",
                                                role: "button",
                                                "data-slide": "prev",
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "text-black",
                                                attrs: {
                                                  icon: "fa-circle",
                                                  size: "3x",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "sr-only" },
                                                [_vm._v("Previous")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "carousel-control-next",
                                              attrs: {
                                                role: "button",
                                                "data-slide": "next",
                                                "aria-controls":
                                                  "monumentosShow___BV_inner_",
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "text-black",
                                                attrs: {
                                                  icon: "fa-circle",
                                                  size: "3x",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "sr-only" },
                                                [_vm._v("Previous")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "overflow-hidden mx-auto d-md-block d-none",
                                          staticStyle: {
                                            height: "94.5px",
                                            width: "940px",
                                            "max-width": "100%",
                                            position: "relative",
                                          },
                                          attrs: {
                                            id: "imageSelectorContainer",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              ref: "imageSelector",
                                              staticClass:
                                                "d-flex thumbnails-container",
                                              staticStyle: {
                                                height: "94.5px",
                                                width: "940px",
                                                "max-width": "72vw",
                                                position: "relative",
                                              },
                                              attrs: { id: "imageSelector" },
                                            },
                                            _vm._l(
                                              _vm.imagenes,
                                              function (imagen, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "thumbnail-slide",
                                                    staticStyle: {
                                                      height: "94.5px",
                                                      width: "140px",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "px-1",
                                                      staticStyle: {
                                                        height: "100%",
                                                        width: "140px",
                                                        "object-fit": "cover",
                                                        "border-radius": "15px",
                                                      },
                                                      attrs: {
                                                        role: "button",
                                                        src: `${_vm.imagenesUrl}/${imagen?.name}`,
                                                        alt: imagen?.altText,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeSlide(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "spinhn-text text-start fw-bold mb-0 pb-0 pt-2",
                                    staticStyle: {
                                      "font-weight": "700",
                                      "font-size": "34pt",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "default.PACK_MICROSITES.DESCRIPTION"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass:
                                    "w-100 border-bottom mt-0 pt-0 mb-1",
                                  staticStyle: {
                                    height: "2vh",
                                    "border-color":
                                      "rgb(242, 90, 85) !important",
                                  },
                                }),
                                _c("mdb-container", [
                                  _c("span", {
                                    staticClass: "text-start px-3",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.paquete?.webDescripciones
                                      ),
                                    },
                                  }),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("mdb-col", { attrs: { col: "12", lg: "4" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column pt-0 pb-0 px-3 text-wrap",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column",
                                staticStyle: { "border-radius": "10px" },
                              },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "mb-3 spainhn-subtitle text-color-spainhn",
                                  },
                                  [
                                    _c("strong", [
                                      _c("small", [
                                        _vm._v(
                                          "¿QUÉ SE INCLUYE EN EL PAQUETE?"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c("div", {
                                  staticClass:
                                    "w-100 border-bottom mt-0 pt-0 mb-1",
                                  staticStyle: {
                                    height: "2vh",
                                    "border-color":
                                      "rgb(242, 90, 85) !important",
                                  },
                                }),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "mdb-row",
                                      { staticClass: "mt-2 gap-2" },
                                      _vm._l(
                                        _vm.entradas,
                                        function (entrada, index) {
                                          return _c(
                                            "mdb-col",
                                            { key: index },
                                            [
                                              _c("RecommendationsCard", {
                                                attrs: {
                                                  autoWidth: "",
                                                  title: entrada.webNombre,
                                                  showDefault: false,
                                                  text: _vm.$t(
                                                    "default.CARDS.PLACE",
                                                    {
                                                      msg: _vm.paquete
                                                        .monumentoId?.nombre,
                                                    }
                                                  ),
                                                  "image-url": `${_vm.imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`,
                                                  showBuyButton:
                                                    _vm.paquete.ventaIndividual,
                                                  showInfo: false,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { class: { "d-none": _vm.eventos.length == 0 } }, [
            _c("p", { staticClass: "spainhn-subtitle" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("default.MAIN_MENU_SERVICES_EVENTOS")) + " "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }