import http from "../http-common";

class ApiServerMonumentsPackage {
  async getPackageMonument(paqueteId, lang){
    return http.get(`/paquete-monumento/${paqueteId}`,{
      params:{
        idioma: lang
      }
    });
  }
} 

export default new ApiServerMonumentsPackage();