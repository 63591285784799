<template>
	<default-layout>
		<SpainhnNavigationBreadcrumb :navigation-links="links">
			<slot>
				<svg class="mx-1 my-auto" xmlns="http://www.w3.org/2000/svg" width="2" height="14" viewBox="0 0 2 14"
					fill="none">
					<path d="M1 0V13.5" stroke="white" />
				</svg>
				<small class="spainhn-small-text text-white my-auto px-1 flex-grow-1 text-start variable-item"
					@click="() => this.$router.push('/contacto')" role="button">{{ $t("default.SERVICES_BAR_CONTACT")
					}}</small>
			</slot>
		</SpainhnNavigationBreadcrumb>
		<div class="container-fluid d-flex justify-content-center pb-2 spainhn-bg-gris-cajas pt-2">
			<div class="pt-4 d-flex flex-wrap">
				<div class="col-md-7 col-12 ps-md-3 ps-4 pe-4">
					<p class="spainhn-text ms-auto mt-3 me-0 text-center spainhn-subtitle-2-black"
						style="font-size: 16px !important">
                        {{ $t("default.PACK_MICROSITES.WELCOME_PACK") }}
					</p>
				</div>
				<mdb-btn color="indigo" class="spainhn-button me-lg-0 me-auto mt-1 ms-auto" size="sm"
					@click="goToBookingSystem">
					<div class="d-flex justify-content-center align-items-center">
						<div class="my-auto">
							<icon-base :height="50" :width="50">
								<icon-carrito />
							</icon-base>
						</div>
						<h6>
							<strong class="spainhn-text">
								<!-- {{ $t('default.SPAINHN_BUTTON_EXPERIENCES') }} -->
								{{ $t("default.PACK_MICROSITES.BUY_PACK") }}
								<br>{{ precioOrientativoPaquete }}
							</strong>
						</h6>
					</div>
				</mdb-btn>
			</div>
		</div>
		<div class="container-fluid px-5">
			<div class="d-flex justify-content-around pt-4 mb-3" style="">
				<div class="d-flex" style="width: 100%">
					<!-- <icon-base :width="55" :height="55" :iconColor="'rgb(0,0,0)'">
                      <icon-casttle></icon-casttle>
                  </icon-base> -->
					<font-awesome-icon icon="fa-compass" size="3x" class="my-auto d-none d-md-flex" />
					<div class="ms-3 h-100 d-flex flex-column text-start">
						<p class="mb-0 mt-auto spainhn-subtitle-2-black">
							<!-- {{ paquete?.nombres?.[0].texto }} -->
							{{ paquete?.name }}
						</p>
						<small class="mb-0 mt-auto spainhn-subtitle-4-black">{{
							address
						}}</small>
					</div>
				</div>
			</div>

			<b-spinner v-bind:class="{ 'd-none': !loading }" variant="danger" size="2x"
				style="width: 5rem; height: 5rem"></b-spinner>
			<div class="d-flex mb-4" v-if="entradas">
				<mdb-row>
					<mdb-col col="12" lg="8">
						<div class="d-flex flex-column w-100">
							<!-- <div>
								<p class="spainhn-subtitle">Contenido del paquete</p>
							</div> -->

							<div class="d-flex justify-content-around px-0 mx-auto w-100 flex-wrap">
								<mdb-row>
									<mdb-col col="12" class="text-start spainhn-text pt-0 pe-0 mb-1 mb-md-5 px-0"
										style="height: 100%">
										<b-carousel id="carousel" v-model="slide" :interval="4000" controls indicators
											img-width="940" img-height="528" style="text-shadow: 1px 1px 2px #333"
											@sliding-start="onSlideStart" @sliding-end="onSlideEnd">
											<b-carousel-slide v-for="(imagen, index) in imagenes" v-bind:key="index">
												<template #img>
													<div class="mb-3 d-flex mx-auto carousel-homeslide px-0 mx-0" style="
															height: 344px;
															width: 940px;
															max-width: 100vw;">
														<img class="my-md-auto mx-auto carousel-spainhn" style="
																height: auto;
																width: auto;
																max-width: 940px;
																max-height: 344px;
																display: block;
																object-fit: cover;
															" :src="`${imagenesUrl}/${imagen.name}`" :alt="imagen?.altText" />
													</div>
												</template>
											</b-carousel-slide>
											<a class="carousel-control-prev" aria-controls="monumentosShow___BV_inner_"
												role="button" data-slide="prev">
												<!-- INSERT MY OWN PREV ICON USING FONT AWESOME  -->
												<font-awesome-icon icon="fa-circle" size="3x"
													class="text-black"></font-awesome-icon>
												<span class="sr-only">Previous</span>
											</a>

											<a class="carousel-control-next" role="button" data-slide="next"
												aria-controls="monumentosShow___BV_inner_">
												<!-- INSERT MY OWN PREV ICON USING FONT AWESOME  -->
												<font-awesome-icon icon="fa-circle" size="3x"
													class="text-black"></font-awesome-icon>
												<span class="sr-only">Previous</span>
											</a>
										</b-carousel>

										<div class="overflow-hidden mx-auto d-md-block d-none" style="
												height: 94.5px;
												width: 940px;
												max-width: 100%;
												position: relative;
											" id="imageSelectorContainer">
											<div class="d-flex thumbnails-container" style="
													height: 94.5px;
													width: 940px;
													max-width: 72vw;
													position: relative;
												" ref="imageSelector" id="imageSelector">
												<div style="height: 94.5px; width: 140px" class="thumbnail-slide"
													v-for="(imagen, index) in imagenes" v-bind:key="index">
													<img role="button" @click="changeSlide(index)" class="px-1" style="
															height: 100%;
															width: 140px;
															object-fit: cover;
															border-radius: 15px;
														" :src="`${imagenesUrl}/${imagen?.name}`" :alt="imagen?.altText" />
												</div>
											</div>
										</div>
									</mdb-col>
								</mdb-row>
							</div>
							<div class="col-12">
								<div class="mt-3">
									<h2 class="spinhn-text text-start fw-bold mb-0 pb-0 pt-2"
										style="font-weight: 700; font-size: 34pt">
										{{ $t("default.PACK_MICROSITES.DESCRIPTION") }}
									</h2>
									<div class="w-100 border-bottom mt-0 pt-0 mb-1" style="
											height: 2vh;
											border-color: rgb(242, 90, 85) !important;
										"></div>

									<mdb-container>
										<span class="text-start px-3"
											v-html="paquete?.webDescripciones"></span>
									</mdb-container>

								</div>
							</div>
						</div>
					</mdb-col>
					<mdb-col col="12" lg="4">
						<div class="d-flex flex-column pt-0 pb-0 px-3 text-wrap">
							<div class="h-100 pt-5 spainhn-bg-gris-cajas px-3 spainhn-text d-flex flex-column"
								style="border-radius: 10px">
								<h5 class="mb-3 spainhn-subtitle text-color-spainhn">
									<strong><small>¿QUÉ SE INCLUYE EN EL PAQUETE?</small></strong>
								</h5>
								<div class="w-100 border-bottom mt-0 pt-0 mb-1" style="
										height: 2vh;
										border-color: rgb(242, 90, 85) !important;
									"></div>
								<div>
									<mdb-row class="mt-2 gap-2">
										<mdb-col v-for="(entrada, index) in entradas" :key="index">
											<RecommendationsCard autoWidth :title="entrada.webNombre"
												:showDefault="false" 
												:text="$t('default.CARDS.PLACE', {msg: paquete.monumentoId?.nombre})"
												:image-url="`${imagenesUrl}/visitas_castillos/${entrada.oldId}/${entrada.banner}`"
												:showBuyButton="paquete.ventaIndividual" :showInfo="false">
											</RecommendationsCard>
										</mdb-col>
									</mdb-row>
								</div>
								<!-- <span
									class="text-start px-3"
									v-html="
										paquete
											.descripcionDelContenidoMultilanguage?.[0]
											.texto
									"
								></span> -->

								<!-- <h5
									class="mb-3 spainhn-subtitle text-color-spainhn text-uppercase"
								>
									<strong
										><small>{{
											$t("default.MICROSITES_SCHEDULE")
										}}</small></strong
									>
								</h5>
								<div
									class="w-100 border-bottom mt-0 pt-0 mb-1"
									style="
										height: 2vh;
										border-color: rgb(242, 90, 85) !important;
									"
								></div>
								<div class="spinhn-text text-start">
									<p
										v-html="
											paquete.horariosMultilanguage?.[0]?.texto
										"
									></p>
								</div> -->
							</div>
						</div>
					</mdb-col>
				</mdb-row>

			</div>

			<div v-bind:class="{ 'd-none': eventos.length == 0 }">
				<p class="spainhn-subtitle">
					{{ $t("default.MAIN_MENU_SERVICES_EVENTOS") }}
				</p>
			</div>
		</div>

		<!-- <trade-spoting v-if="monumentoUrl == 'castillo-de-belmonte'"></trade-spoting> -->
	</default-layout>
</template>

<script>
import DefaultLayout from "../layouts/DefaultLayout.vue";
import ApiService from "../services/ApiService";
import ApiServiceMonumentsPackage from "../services/ApiServiceMonumentsPackage";
import IconBase from "../components/IconBase.vue";
import IconCasttle from "../components/icons/IconCasttle.vue";
// import Cards from '../components/Cards.vue'
import IconCarrito from "../components/icons/IconCarrito.vue";
import RecommendationsCard from "../components/RecommendationsCard.vue";
import SpainhnNavigationBreadcrumb from "../components/SpainhnNavigationBreadcrumb.vue";
import {
	mdbCol,
	mdbRow,
	mdbListGroup,
	mdbListGroupItem,
	mdbIcon,
} from "mdbvue";
// import TradeSpoting from '../components/TradeSpoting.vue';

export default {
	components: {
		DefaultLayout,
		IconBase,
		// IconCasttle,
		// Cards,
		IconCarrito,
		SpainhnNavigationBreadcrumb,
		mdbCol,
		mdbRow,
		RecommendationsCard,
		// TradeSpoting
	},
	data() {
		return {
			address: null,
			// monumentoNombre: null,
			monumentoNombres: [],
			monumentoUrl: null,
			monumentoId: null,
			entradas: [],
			paquete: null,
			imagenesUrl: null,
			imagenes: [],
			eventos: [],
			clorian: false,
			loading: false,
			clorianPos: null,
			links: [
				{ text: "Inicio", navigation: true, navigationLink: "/" },
				{ text: "Paquetes", navigation: true, navigationLink: "/" },
			],
			slide: 0
		};
	},
	setup() { },
	methods: {
		encodeUrl(name = "") {
			let rawName = name.replaceAll(",", "");
			rawName = rawName.replaceAll(".", "");

			let sanitizedName = rawName
				.normalize("NFD")
				.replace(/[\u0300-\u036f]|[^0-9a-zA-Z]+[\u0020]/g, "");
			let sanitizedNameLowercase = sanitizedName
				.toLowerCase()
				.replace(/[\u0020]/g, "-");

			return sanitizedNameLowercase;
		},
		goToEntrada(entrada) {
			if (
				this.paquete.monumentoId.nombre ===
				"Castillo de San Pedro de Jaca (Ciudadela)"
			) {
				window.open(process.env.VUE_APP_JACA_URL);
			} else {
				this.$router.push(
					`/castillos/tarifas/${this.encodeUrl(this.paquete.monumentoId.nombre)}/${entrada._id}`
				);
			}
		},
		goToEvento(event) {
			this.$router.push(
				"/castillos/eventos/" +
				this.monumentoUrl +
				"/" +
				event.entradaId
			);
		},
		goToCastillosYPalacios() {
			let $vm = this;
			$vm.$router.push("/castillos");
		},
		goToCastillos() {
			let $vm = this;
			$vm.$router.push("/castillos/" + $vm.monumentoUrl);
		},
		async obtenerPaquete(id, lang) {
			const res = await ApiServiceMonumentsPackage.getPackageMonument(id, lang);
			return res.data;
		},
		goToBookingSystem() {
			this.$router.push({
				name: "ReservarPackMonumentos",
				query: { tipo: "monumentos", id: this.paquete._id },
			});
		},
		getIcon(categoria) {
			if (categoria) {
				const icons = [];
				if (categoria.includes("Gastronomical")) {
					icons.push("fa-utensils");
				}
				if (categoria.includes("Cultural")) {
					icons.push("fa-glasses");
				}
				return icons;
			} else {
				return ["fa-crown"];
			}
		},
		goToBuy({ id }) {
			this.$router.push(`/castillos/reservar/idvisita:${id}`);
		},
		onSlideStart() {
			this.sliding = true;
		},
		onSlideEnd() {
			this.sliding = false;
		},
	},
	computed: {
		selectedIdiomaIndex() {
			let $vm = this;
			let idiomaIndex = 0;
			if ($vm.$i18n.locale == "es") {
				idiomaIndex = 0;
			} else if ($vm.$i18n.locale == "en") {
				idiomaIndex = 1;
			} else if ($vm.$i18n.locale == "fr") {
				idiomaIndex = 2;
			} else if ($vm.$i18n.locale == "de") {
				idiomaIndex = 3;
			} else if ($vm.$i18n.locale == "ja") {
				idiomaIndex = 4;
			}
			return idiomaIndex;
		},
		selectedIdioma() {
			let $vm = this;

			return $vm.$i18n.locale;
		},
		monumentoNombre() {
			let $vm = this;
			// console.log($vm.monumentoNombres)
			try {
				return $vm.monumentoNombres[$vm.selectedIdiomaIndex].texto;
			} catch (err) {
				return "";
			}
		},
		precioOrientativoPaquete() {
			if (this.paquete?.precioOrientativo) {
				return `${ this.$t( "default.BUTTONS.PRICE_ORIENTATION", { price: this.paquete?.precioOrientativo.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })} )  }`;
			}
			return '';
		}
	},
	async mounted() {
		let $vm = this;
		this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
		// $vm.monumentoNombre = $vm.$route.params.monumentoNombre;
		$vm.loading = true;
		// ApiService.getEdificacion($vm.$i18n.locale, $vm.monumentoUrl)
		//     .then( result => {
		//         let data = result.data;
		//         $vm.monumentoNombres = result.data.nombres;
		//         $vm.address = data.direccion;
		//         $vm.monumentoId = data.id;

		//         $vm.clorianPos = data.clorianPos;
		//         // $vm.edificios = [...result.data];

		//         if(data.apiDeVentasActive == true && data.apiDeVentasUrl == 'clorian'){
		//             $vm.clorian = true;
		//             ClorianService.consultarProductos($vm.monumentoNombres[0].texto, $vm.clorianPos)
		//                 .then( response => {
		//                     $vm.loading = false;
		//                     $vm.entradas = [...response.data.products];
		//                     // console.log($vm.entradas);
		//                 })
		//                 .catch( err => {
		//                     console.log("Error en la respuesta de clorian")
		//                     $vm.loading = false;
		//                     console.log(err);
		//                 })
		//         }
		//         else{
		//             ApiService.getVisitasByEdificacion($vm.$i18n.locale, $vm.monumentoId)
		//                 .then( result => {
		//                     $vm.loading = false;
		//                     $vm.entradas = [...result.data];
		//                 })
		//                 .catch( err=> {
		//                     console.log("Error en las visitas")
		//                     $vm.loading = false;
		//                     console.log(err);
		//                 })
		//             ApiService.getEventosByMonumento($vm.$i18n.locale, $vm.monumentoId )
		//                 .then( result => {
		//                     $vm.eventos = result.data.filter( resultado => resultado.fechas.length > 0);

		//                 } )
		//                 .catch(err => {
		//                     console.log("Error en los eventos")
		//                     console.log(err);
		//                 })

		//         }
		//     })
		//     .catch(err => {
		//         console.log("Error consultado info de edificación")
		//         console.log(err);
		//     })
		const _language = this.$store.getters.getIdioma ?? "es"
		$vm.paquete = await this.obtenerPaquete($vm.$route.params.paqueteId, _language );
		$vm.entradas = $vm.paquete.content;
		this.imagenes = this.entradas
			.map((e) => e.imagenes)
			.reduce((acc, curr) => acc.concat(curr), []);

		this.imagenes.sort((a, b) => {
			return (a.order ?? 0) - (b.order ?? 0);
		});

		// $vm.monumentoUrl = $vm.entradas.find(item => item.monumentoId)?.castilloNombres?.find(item => item.idioma == 'es')?.texto.replaceAll(" ", "-").toLowerCase();
		$vm.loading = false;
	},
};
</script>

<style scoped>
.thumbnails-container {
	transition: all 0.5s;
}

.thumbnail-slide {
	/* position:absolute; */
	transform: translate(0px);
	transition: all 0.5s;
}

@media (min-width: 768px) {
	.text-columns {
		column-count: 2;
		/* height: 50vh; */
	}

	.carousel-spainhn {
		height: 528px;
		width: auto;
		/* max-width: 100vw; */
	}
}

@media (max-width: 768px) {
	.text-columns {
		column-count: 2;
		/* height: 50vh; */
	}

	.carousel-homeslide {
		height: 300px !important;
	}

	.carousel-spainhn {
		height: 100%;
		width: 100% !important;
		max-width: 100vw;
	}
}

@media (max-width: 424px) {
	.text-columns {
		column-count: 1;
		/* height: 50vh; */
	}
}

.html-container {
	position: relative;
	overflow: hidden;
	max-height: 320px;
}

.html-container p span {
	color: black !important;
}

.html-container p {
	color: black !important;
}

.read-more-btn {
	width: 85px;
	height: 35px;
	flex-shrink: 0;
	border-radius: 20px;
	/* background: #D9D9D9;
      color: #FFF; */
	text-align: center;
	font-family: "Montserrat";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	border: none;
	padding: 0;
}

.loader-component {
	position: fixed;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
